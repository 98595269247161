a {
  text-decoration: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fa-lg {
  padding-left: 5px;
  line-height:0.85em !important;
}

.fa-filter {
  padding-left: 1px !important;
}

.mapboxgl-ctrl-top-left {
  top: 70px!important;
}

.flexSpacer {
  margin-right: auto !important;
  box-sizing: border-box;
}

.flexSpacerRight {
  margin-left: 22px !important;
  margin-right: 0 !important;
  box-sizing: border-box;
}

.custom-popup .mapboxgl-popup-content {
  width: 350px;
}

@keyframes growGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.grow-gradient {
  background: linear-gradient(270deg, #d9e7df, #a7cba9); /* Green gradient */
  background-size: 400% 400%;
  animation: growGradient 2s ease infinite;
}

.flashing {
  animation: flashingBackground 1s infinite;
  background: linear-gradient(270deg, #ffffb3, #ebe5b0); /* Green gradient */
  background-size: 400% 400%;
  animation: growGradient 1.8s ease infinite;
  font-size: 13px;
  height: 45px;
}

.tableRowError {
  height: 45px;
  font-size: 13px;
  background-color: rgb(243, 228, 234);
  border-bottom: #ccc 1px solid;
}

.tableRow {
  height: 45px;
  font-size: 13px;
  border-bottom: #ccc 1px solid;
}